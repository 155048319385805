'use strict';
import { localize } from "./localization.mjs"

/**
 * Created by bo on 07.06.2017.
 */

var textPwdLevel1 = "Sehr schwaches Passwort";
var textPwdLevel2 = "Schwaches Passwort";
var textPwdLevel3 = "Gutes Passwort";
var textPwdLevel4 = "Starkes Passwort";
var textPwdLevel5 = "Sehr starkes Passwort";

localize('PWDQUALITY').then(function (__) { //
    textPwdLevel1 = __("Sehr schwaches Passwort");
    textPwdLevel2 = __("Schwaches Passwort");
    textPwdLevel3 = __("Gutes Passwort");
    textPwdLevel4 = __("Starkes Passwort");
    textPwdLevel5 = __("Sehr starkes Passwort");
});

var $password_input = $('.pwd_quality_input');

$password_input.keyup(function () {
    var strength = password_strength($password_input.val());
    $('#pwd_quality').find('.bar').css('width', strength + '%');
    $('#pwd_quality_text').html(password_strength_text(strength));
});

function password_strength_text(strength) {
    if (strength < 30) {
        return textPwdLevel1;
    } else if (strength < 55) {
        return textPwdLevel2;
    } else if (strength < 75) {
        return textPwdLevel3;
    } else if (strength < 85) {
        return textPwdLevel4;
    } else {
        return textPwdLevel5;
    }
}

function password_strength(pwd) {
    var strength = 0;
    var last = null;

    var uppercase_bonus = 8;
    var lowercase_bonus = 8;
    var number_bonus = 8;
    var symbol_bonus = 20;
    var repetition_malus = 1;
    var sequential_malus = 1;
    var char_count = {};

    // Alle Zeichen im Passwort durchgehen und Punkte vergeben
    for (var i = 0; i < pwd.length; ++i) {
        var c = pwd.charCodeAt(i);
        if (char_count[c] === undefined) {
            char_count[c] = 1;
        } else {
            char_count[c] += 1;
        }

        var add = 2 / char_count[c];

        // Widerholungen sind schlecht
        if (last === c) {
            add -= repetition_malus;
            repetition_malus *= 2;
        } else {
            repetition_malus = 1;
        }

        // Aufeinanderfolgende Buchstaben sind schlecht(z.B. abcdefgfedcba)
        if (Math.abs(c - last) === 1) {
            add -= sequential_malus;
            sequential_malus *= 2;
        } else {
            sequential_malus = 1;
        }

        if (c >= 65 && c <= 90) // Uppercase
        {
            add += uppercase_bonus;
            uppercase_bonus /= 2;
        } else if (c >= 97 && c <= 122) // Lowercase
        {
            add += lowercase_bonus;
            lowercase_bonus /= 2;
        } else if (c >= 48 && c <= 57) // Ziffer
        {
            add += number_bonus;
            number_bonus /= 2;
        } else // Symbol
        {
            add += symbol_bonus;
            symbol_bonus /= 2;
        }

        // Das Passwort wird mit zunehmender Länge auf jeden Fall nicht schlechter
        if (add > 0) {
            strength += add;
        }

        last = c;
    }

    return strength < 100 ? strength : 100;
}